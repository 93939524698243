.image-viewer-app {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #525659;
  display: flex;
  flex-direction: column;
}

.image-container {
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-renderer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    //max-width: 100%;
    //max-height: 100%;
    //object-fit: contain;
    user-select: none;
  }
}

.image-toolbar {
  position: sticky;
  font-size: 12px;
  top: 10px; // Matches the padding of pdf-viewer
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  padding: 6px 6px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 6px;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  button {
    padding: 4px 8px;
    border: none;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    // border: 1px solid #ddd;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  span {
    color: white;
    padding: 0 6px;
    font-weight: 500;
  }
}

.image-loading,
.image-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-size: 16px;
}
