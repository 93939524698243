:root {
    --shadow-color: 122deg 100% 2%;
    --shadow-elevation-low:
      0px 0.9px 1px hsl(var(--shadow-color) / 0.34),
      0px 1.4px 1.6px -1.2px hsl(var(--shadow-color) / 0.34),
      0px 3.5px 3.9px -2.5px hsl(var(--shadow-color) / 0.34);
    --shadow-elevation-medium:
      0px 0.9px 1px hsl(var(--shadow-color) / 0.36),
      0px 2.8px 3.1px -0.8px hsl(var(--shadow-color) / 0.36),
      0px 7.1px 8px -1.7px hsl(var(--shadow-color) / 0.36),
      -0.1px 17.3px 19.5px -2.5px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-high:
      0px 0.9px 1px hsl(var(--shadow-color) / 0.34),
      0px 5px 5.6px -0.4px hsl(var(--shadow-color) / 0.34),
      -0.1px 9.4px 10.6px -0.7px hsl(var(--shadow-color) / 0.34),
      -0.1px 15.4px 17.3px -1.1px hsl(var(--shadow-color) / 0.34),
      -0.1px 24.6px 27.7px -1.4px hsl(var(--shadow-color) / 0.34),
      -0.2px 38.5px 43.3px -1.8px hsl(var(--shadow-color) / 0.34),
      -0.3px 58.6px 65.9px -2.1px hsl(var(--shadow-color) / 0.34),
      -0.5px 86.3px 97.1px -2.5px hsl(var(--shadow-color) / 0.34);
  }
/* Taskbar styling */
.taskbar {
    position: fixed;
    font-size: 14px;
    font-weight: 500;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    // background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 10px;
    gap: 10px;
    border-radius: 20px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Make sure the dock stays above other elements */
    // box-shadow: var(--shadow-elevation-high);
    -webkit-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
    box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
}

/* App buttons styling */
.appButton {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Hover effect to scale the buttons */
.appButton:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.3);
}

/* Icon inside the buttons (Optional) */
.appButton img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.plus {
  width: 40px;
  height: 40px;
}

.plus-icon {
  font-weight: bold;
  font-size: 20px;
}
