.buy-galactica-modal {
    padding: 20px;
    
    p {
        font-size: 16px;
        margin-bottom: 20px;
        color: #333;
    }

    button {
        padding: 8px 16px;
        background-color: #4CAF50;
        border: none;
        border-radius: 4px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: #45a049;
        }
    }
} 