.chat-messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  height: 100%;
  
  .message {
    display: flex;
    margin-bottom: 8px;
    
    &.user-message {
      justify-content: flex-end;
      
      .message-bubble {
        background-color: #0084ff;
        color: white;
        border-radius: 18px 18px 4px 18px;
      }
    }
    
    &.ai-message {
      justify-content: flex-start;
      
      .message-bubble {
        background-color: #f0f0f0;
        color: #333;
        border-radius: 18px 18px 18px 4px;
      }
    }

    &.tool-message {
      justify-content: flex-start;

      .message-bubble {
        background-color: #f0f0f010;
        font-size: 12px;
        font-style: italic;
        color: white;
        border-radius: 12px 12px 12px 4px;
        outline: 1px solid #f0f0f020;
      }
    }
    
    .message-bubble {
      padding: 12px 16px;
      max-width: 70%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      
      .message-content {
        word-wrap: break-word;
        
        &.loading {
          min-width: 60px;
          display: flex;
          justify-content: center;
        }
        
        .typing-indicator {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #999;
            border-radius: 50%;
            opacity: 0.6;
            animation: typing-animation 1.4s infinite ease-in-out both;
            
            &:nth-child(1) {
              animation-delay: 0s;
            }
            
            &:nth-child(2) {
              animation-delay: 0.2s;
            }
            
            &:nth-child(3) {
              animation-delay: 0.4s;
            }
          }
        }
      }
    }
  }
}

@keyframes typing-animation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}
