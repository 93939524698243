/* src/apps/PDFApp.css */
.pdfApp {
  position: relative;
  height: 100%;
  width: auto;
  padding: 10px;
}

.toolbar {
  position: sticky;
  font-size: 12px;
  padding: 6px 6px;
  border-radius: 10px;
  border: 1px solid #ccc;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  gap: 6px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.toolbar button {
  padding: 4px 8px;
  border: none;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.toolbar button.active {
  background-color: #d0d0d0;
}

.pdfViewer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pdfViewer .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center; /* centers the pages horizontally */
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
}

.pageBreak {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  position: relative;
}
