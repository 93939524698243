:root {
  --primary-color: #0066ff;
  --primary-color-dark: #0052cc;
  --danger-color: #dc3545;
  --danger-color-dark: #bd2130;
  --text-primary: #202124;
  --text-secondary: #5f6368;
  --text-tertiary: #80868b;
  --bg-secondary: #f8f9fa;
  --border-color: #e1e1e1;
}

.settings-app {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 16px;
    border-bottom: 1px solid #e1e1e1;

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }
  }

  &__tabs {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #e1e1e1;

    .tab {
      background: none;
      border: none;
      cursor: pointer;
      color: #666;
      padding: 4px 0;

      &.active {
        color: #0066ff;
      }

      &:hover {
        color: #333;
      }
    }
  }

  &__content {
    padding: 16px;
    flex: 1;
    overflow-y: auto;
  }

  .integrations-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .current-integrations {
    padding: 16px;
    background: #f8f9fa;
    border-radius: 8px;

    h4 {
      margin: 0 0 16px 0;
      color: #202124;
    }

    .integration-item.current {
      background: white;
      border: 1px solid #e1e1e1;
    }

    .integration-meta {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-top: 4px;
      font-size: 12px;

      .status {
        padding: 2px 8px;
        border-radius: 12px;
        
        &.connected {
          background: #e6f4ea;
          color: #137333;
        }
        
        &.disconnected {
          background: #fce8e6;
          color: #c5221f;
        }
      }

      .date {
        color: #5f6368;
      }
    }
  }

  .no-integrations {
    color: #5f6368;
    text-align: center;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 8px;
  }

  .available-integrations {
    h4 {
      margin: 0 0 16px 0;
      color: #202124;
    }
  }

  .remove-button {
    background: #fce8e6;
    color: #c5221f;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background: #fad2cf;
    }
  }
}

.integrations-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.integration-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;

  .integration-info {
    display: flex;
    align-items: center;
    gap: 12px;

    .integration-icon {
      font-size: 24px;
    }

    .integration-details {
      h4 {
        margin: 0;
        font-weight: 500;
      }

      p {
        margin: 4px 0 0;
        font-size: 14px;
        color: #666;
      }
    }
  }

  .add-button {
    background: #0066ff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #0052cc;
    }
  }

  button:disabled {
    background: #f1f3f4;
    color: #5f6368;
    cursor: not-allowed;
  }
}

.account-settings {
  color: #666;
}

.connected-badge {
  display: inline-block;
  background: #e6f4ea;
  color: #137333;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-top: 4px;
}

.error {
  color: #d93025;
  padding: 16px;
}

.loading {
  padding: 16px;
  color: #666;
}

.error {
  padding: 16px;
  color: #d93025;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .retry-button {
    background: #1a73e8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #1557b0;
    }
  }
}

.apps-container {
  .current-apps,
  .available-apps {
    margin-bottom: 2rem;

    h4 {
      margin-bottom: 1rem;
      color: var(--text-primary);
    }
  }

  .apps-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .app-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: var(--bg-secondary);
    border-radius: 8px;
    border: 1px solid var(--border-color);

    .app-info {
      display: flex;
      align-items: center;
      gap: 1rem;

      .app-icon {
        font-size: 1.5rem;
      }

      .app-details {
        h4 {
          margin: 0 0 0.25rem;
          color: var(--text-primary);
        }

        p {
          margin: 0;
          color: var(--text-secondary);
          font-size: 0.9rem;
        }

        .app-meta {
          margin-top: 0.5rem;
          font-size: 0.8rem;
          color: var(--text-tertiary);
        }
      }
    }

    .add-button, .remove-button {
      padding: 0.5rem 1rem;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.2s ease;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .add-button {
      background: var(--primary-color);
      color: white;

      &:hover:not(:disabled) {
        background: var(--primary-color-dark);
      }
    }

    .remove-button {
      background: var(--danger-color);
      color: white;

      &:hover:not(:disabled) {
        background: var(--danger-color-dark);
      }
    }
  }
}

.debug-section {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 4px;

  h3 {
    margin-bottom: 1rem;
  }

  .debug-tabs {
    margin-bottom: 1rem;
    
    button {
      margin-right: 0.5rem;
      padding: 0.5rem 1rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      background: white;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }

      &.active {
        background: #e0e0e0;
      }
    }
  }
} 