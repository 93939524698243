.context-section {
  padding: 8px;
  border-top: 1px solid var(--border-color);
  
  .context-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    
    .context-add {
      padding: 2px 8px;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      background: var(--surface-2);
      cursor: pointer;
      
      &:hover {
        background: var(--surface-3);
      }
    }
  }
  
  .context-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .context-item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 8px;
      background: var(--surface-1);
      border-radius: 4px;
      
      &.selection {
        border-left: 3px solid var(--accent);
      }
      
      .context-item-title {
        font-size: 0.9em;
        color: var(--text-secondary);
      }
      
      .selection-text {
        flex: 1;
        font-family: monospace;
        white-space: pre-wrap;
        font-size: 0.9em;
      }
      
      .context-item-remove {
        padding: 0 4px;
        border: none;
        background: none;
        color: var(--text-secondary);
        cursor: pointer;
        
        &:hover {
          color: var(--error-text);
        }
      }
    }
  }
} 