.system-location-selector {  
  border-radius: 12px;
  font-size: 13px;

  &.expanded {
    background: rgba(255, 255, 255, 0.1);

    .current-location {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 4px;
      border-radius: 8px 8px 0 0;
    }
  }
  .available-locations {
    padding: 8px;
  }
  
  .current-location {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 8px;
    color: #6b936c;
    border-radius: 8px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .location-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 1;
      height: 20px;
      width: 20px;

      .pulse-indicator {
        position: absolute;
        width: 6px;
        height: 6px;
        background: #4CAF50;
        border-radius: 50%;
        right: -1px;
        bottom: -1px;
        animation: pulse 1s infinite;
      }
    }

    > span:last-child {
      font-size: 16px;
      margin-left: auto;
      opacity: 0.7;
    }

    svg {
      margin-left: 4px;
      color: white;
    }

    .chevron {
      color: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      .chevron {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .location-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px 16px;
    padding-left: 32px; // Indent submenu items
    gap: 8px;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    text-align: left;
    border-radius: 8px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.active {
      color: white;
    }

    .check {
      margin-left: auto;
      color: white;
    }

    .location-icon {
      &.gal-box {
        // background: #000;
        // border-radius: 2px;
        // animation: glow 1s infinite;
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 229, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(0, 229, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 229, 255, 0);
  }
}