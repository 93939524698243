body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Reset basic input styles */
input {
  all: unset; /* Removes all default styles */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  width: 100%; /* Optional: make it responsive */
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit; /* Ensures text color inherits from parent */
  font: inherit; /* Inherits font settings from parent */
  line-height: inherit; /* Inherits line-height */
  appearance: none; /* Removes system styling like shadows in some browsers */
  outline: none; /* Remove default focus outline */
}

textarea {
  resize: none; /* Disable resizing if textareas are being rendered */
  border: none; /* Remove borders */
  outline: none; /* Remove default focus outline */
  font-family: inherit; /* Inherit font */
  font-size: inherit; /* Inherit font size */
  background: transparent; /* Make transparent if needed */
}

button {
  all: unset; /* Reset all default button styles */
  box-sizing: border-box; /* Ensures padding and border are included in the element's width/height */
  cursor: pointer; /* Ensures the correct pointer for the button */
  display: inline-flex; /* Flex behavior to better align content inside */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
}