.contents-wrapper {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 10px;
  border-radius: 20px;
  z-index: 1000;
  -webkit-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
  box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
}

.contents-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  backdrop-filter: blur(5px);
  font-size: 18px;
  line-height: 18px;
  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.contents-panel {
  position: fixed;
  bottom: 80px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  padding: 15px;
  border-radius: 20px;
  color: #fff;
  z-index: 999;
  -webkit-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);
  box-shadow: 0px 28px 58px 4px rgba(0,0,0,0.55);

  h3 {
    margin: 0 0 10px 0;
    font-size: 14px;
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.8);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .contents-item {
    padding: 8px 12px;
    margin: 4px 0;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.3);
    }

    small {
      opacity: 0.6;
      margin-left: 8px;
    }
  }
}
