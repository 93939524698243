.notes-editor-container {
    height: 100%;
    
    .notes-editor-content {
        height: 100%;

    }
} 
.prose-mirror-editor {
    min-height: 0;
    height: 100%;
    outline: none;
    padding: 1rem;
    
    // Basic text styling
    font-size: 14px;
    line-height: 1.6;
    color: var(--text-normal);

    // Block elements
    p, h1, h2, h3, h4, h5, h6, ul, ol, blockquote {
        margin: 0;
        margin-bottom: 1em;
    }

    // Headings
    h1 { font-size: 2em; font-weight: 600; }
    h2 { font-size: 1.5em; font-weight: 600; }
    h3 { font-size: 1.17em; font-weight: 600; }
    h4 { font-size: 1em; font-weight: 600; }
    h5 { font-size: 0.83em; font-weight: 600; }
    h6 { font-size: 0.67em; font-weight: 600; }

    // Lists
    ul, ol {
        padding-left: 1.5em;
    }

    // Blockquotes
    blockquote {
        border-left: 3px solid var(--border-color);
        padding-left: 1em;
        color: var(--text-muted);
    }

    // Code blocks
    pre {
        background: var(--background-secondary);
        padding: 0.75em 1em;
        border-radius: 4px;
        margin-bottom: 1em;
    }

    .ProseMirror {
        min-height: 0;
        height: 100%;
    }
}