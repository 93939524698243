// Common variables
:root {
    --border-color: #eee;
    --text-muted: #666;
    --background-hover: #f5f5f5;
    --container-padding: 20px;
    --background-primary: #fff;
    --text-normal: #333;
    --background-secondary: #f5f5f5;
}

// Add dark mode variables
:root.dark-mode {
    --border-color: #2d2d2d;
    --text-muted: #888;
    --background-hover: #2d2d2d;
    --background-primary: #1a1a1a;
    --text-normal: #e0e0e0;
    --background-secondary: #2d2d2d;
}

// Common button styles
%button-base {
    padding: 4px 8px;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--text-muted);
    
    &:hover {
        color: #333;
    }
}

// Common header styles
%header-base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid var(--border-color);
}

// Notes Overview Styles
.notesOverview {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    
    .notesOverviewHeader {
        @extend %header-base;
        margin-bottom: 10px;
        padding: 10px calc(var(--container-padding) + 16px);
        
        .createNoteButton {
            font-size: 14px;  
            font-weight: 500;
            padding: 6px 12px;
            background: #007AFF;
            color: white;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            
            &:hover {
                background: #0056b3;
            }
        }

        .appTitle {
            color: var(--text-normal);
            font-size: 16px;
            font-weight: 600;
        }
    }
    
    .recentNotesList {
        flex: 1;
        overflow-y: auto;
        padding: 10px var(--container-padding);
        
        .noteItem {
            padding: 16px;
            border-bottom: 1px solid var(--border-color);
            cursor: pointer;
            border-radius: 8px;
            
            &:hover {
                background: var(--background-hover);
            }
            
            .notePreview {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .noteTitle {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--text-normal);
                }
                
                .noteTimestamp {
                    font-size: 12px;
                    color: var(--text-muted);
                }
            }
        }
        
        .emptyState {
            text-align: center;
            color: var(--text-muted);
            margin-top: 40px;
        }
    }
}
.notes-app {
    height: 100%;
}
// Notes Editor Styles
.notes-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--background-primary);
    color: var(--text-normal);
    border-radius: 4px;
    overflow: hidden;
    
    .notes-header {
        @extend %header-base;

        .notes-header-left {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            button {
                @extend %button-base;
            }

            h3 {
                margin: 0;
            }
        }

        button {
            @extend %button-base;
        }

        .notes-header-right {
            display: flex;
            align-items: center;
            gap: 8px;
            
            .theme-toggle {
                @extend %button-base;
                font-size: 14px;
                padding: 4px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    // Editor specific styles
    .notes-editor-container {
        flex: 1;
        overflow-y: auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        min-height: 0;
        
        .notes-editor-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-height: 0;
            height: 100%;

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                min-height: 0;
            }
        }

        .notes-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-height: 0;
            height: 100%;

            .ProseMirror {
                flex: 1;
                min-height: 100%;
                outline: none;
                padding: 1rem 0;
                
                // Basic text styling
                font-size: 14px;
                line-height: 1.6;
                color: var(--text-normal);
                background: var(--background-primary);

                // Headings
                h1 { font-size: 2em; margin: 0.67em 0; }
                h2 { font-size: 1.5em; margin: 0.75em 0; }
                h3 { font-size: 1.17em; margin: 0.83em 0; }
                h4 { margin: 1.12em 0; }
                h5 { font-size: 0.83em; margin: 1.5em 0; }
                h6 { font-size: 0.75em; margin: 1.67em 0; }

                // Paragraphs and lists
                p { margin: 1em 0; }
                
                ul, ol {
                    padding-left: 2em;
                    margin: 1em 0;
                }

                // Block quotes
                blockquote {
                    margin: 1em 0;
                    padding-left: 1em;
                    border-left: 3px solid var(--border-color);
                    color: var(--text-muted);
                }

                // Code blocks
                pre {
                    background: var(--background-secondary);
                    padding: 0.75em 1em;
                    border-radius: 4px;
                    overflow-x: auto;
                }

                code {
                    font-family: monospace;
                    background: var(--background-secondary);
                    padding: 0.2em 0.4em;
                    border-radius: 3px;
                    font-size: 0.9em;
                }

                // Tables
                table {
                    border-collapse: collapse;
                    margin: 1em 0;
                    
                    td, th {
                        border: 1px solid var(--border-color);
                        padding: 0.4em 0.8em;
                    }
                }
            }
        }
    }
}

.savingIndicator {
    font-size: 0.8em;
    color: var(--text-muted);
    margin-left: 10px;
}
