.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
  }
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
}

.modalContent h2 {
  margin-top: 0;
}

.modalContent ul {
  list-style: none;
  padding-left: 0;
}

.modalContent li {
  margin: 8px 0;
}

.modalContent button {
  margin-right: 10px;
}
