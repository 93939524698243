.text-diff-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;

  .diff-container {
    display: flex;
    gap: 15px;
    flex: 1;
    min-height: 0; /* Important for flex child to respect parent height */
    width: 100%;
    overflow: hidden;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .diff-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    min-width: 0; /* Prevent flex items from overflowing */
    min-height: 0; /* Important for flex child to respect parent height */

    h3 {
      margin: 0;
      padding: 8px;
      text-align: center;
      color: white;
      flex-shrink: 0;
      font-size: 1rem;
    }

    .text-content {
      flex: 1;
      padding: 12px;
      overflow: auto;
      white-space: pre-wrap;
      word-break: break-word;
      font-family: monospace;
      font-size: 13px;
      background-color: #f8f8f8;
      min-height: 0; /* Important for flex child to respect parent height */
    }

    .empty-text {
      color: #999;
      font-style: italic;
    }
  }

  .before {
    border: 2px solid #d32f2f;
    
    h3 {
      background-color: #d32f2f;
    }
  }

  .after {
    border: 2px solid #388e3c;
    
    h3 {
      background-color: #388e3c;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    flex-shrink: 0;

    button {
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      border: none;
    }

    .confirm-button {
      background-color: #388e3c;
      color: white;
    }

    .cancel-button {
      background-color: #f5f5f5;
      color: #333;
      border: 1px solid #ddd;
    }
  }
} 