.notes-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  
  .notes-editor-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    flex-shrink: 0;
    
    button {
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      
      &:hover {
        background-color: #e0e0e0;
      }
    }
    
    .back-button {
      margin-right: auto;
    }
  }
  
  .notes-editor-content {
    flex: 1;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}