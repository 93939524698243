.menu-button-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 8px;
  cursor: default;
  border: none;
  font-size: 10px;
  backdrop-filter: blur(5px);
  transition: background 0.1s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  &:active, &.active {
    background: rgba(255, 255, 255, 0.1);
  }
}

.menu-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: -4px;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 8px;
  min-width: 240px;
  box-shadow: 0px 28px 58px 4px rgba(0, 0, 0, 0.55);
  z-index: 1000;
  animation: menuFadeIn 0.1s ease;
  
  .menu-item {
    padding: 8px 12px;
    color: #fff;
    cursor: pointer;
    border-radius: 8xp;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:last-child {
      border-radius: 8px 8px 12px 12px;
    }
    &.current-location {
      border-radius: 8px;
    }
  }
}

.menu-item {
  padding: 4px 8px;
  color: #fff;
  font-size: 13px;
  cursor: default;
  white-space: nowrap;
  height: 22px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  &.disabled {
    color: rgba(255, 255, 255, 0.4);
    pointer-events: none;
  }
}

.menu-separator {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 4px 0;
}

@keyframes menuFadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 