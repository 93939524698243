.chat-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 10px;
  border-radius: 20px;
  z-index: 1000;
  box-shadow: 0px 28px 58px 4px rgba(0, 0, 0, 0.55);
}

button.newChatButton {
  font-size: 14px;
  line-height: 100%;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    color: white;
  }
}

button.primary-button {
  padding: 8px 15px;
  border: none;
  border-radius: 20px;
  background: #007AFF;
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}


.chat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  backdrop-filter: blur(5px);
  font-size: 18px;
  line-height: 18px;

  &:hover {
    transform: scale(1.05);
  }
}

.chat-panel {
  position: absolute;
  right: 0;
  bottom: 80px;
  width: 500px;
  height: calc(100vh - 160px);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  overflow: visible;
  backdrop-filter: blur(10px);

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 8px;
    padding: 15px;

    .mode-selector {
      display: flex;
      gap: 8px;
      margin: 0 8px;
      align-items: center;
      height: fit-content;
    }

    .header-buttons {
      display: flex;
      gap: 8px;
      align-items: center;
      .chatHeaderButton {
        font-size: 14px;
        line-height: 100%;
        padding: 4px 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        cursor: pointer;
        height: auto;
        min-height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: fit-content;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          color: white;
        }
      }

      .closeButton {
        background: none;
        font-size: 20px;
        line-height: 24px;
        border-radius: 4px;
        padding: 0;
        width: 24px;
        height: 24px;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          color: white;
        }
      }
    }
  }

  .panelTitle {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.8);
    padding: 4px 0;
    margin: 0;
    display: inline-block;
  }

  .messages {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .message {
      max-width: 80%;
      padding: 10px;
      border-radius: 12px;

      &.user {
        align-self: flex-end;
        background: #007AFF;
        color: white;
      }

      &.system, &.status {
        align-self: center;
        background: #00850380;
        color: white;
        font-size: 0.6em;
      }

      &.ai,
      &.assistant {
        align-self: flex-start;
        background: #3f3f3f80;
        color: white;
      }

      .message-content {
        white-space: pre-wrap;
      }

      .message-timestamp {
        display: none;
      }

      .action-statuses {
        margin-top: 8px;
        font-size: 12px;

        .action-status {
          padding: 4px 8px;
          margin: 2px 0;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.2);

          &.pending {
            color: #f0ad4e;
          }

          &.success {
            color: #5cb85c;
          }

          &.error {
            color: #d9534f;

            .action-error {
              font-size: 11px;
              margin-top: 2px;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .context-section {
    padding: 10px 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .context-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      span {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }

      .context-add {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        color: white;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        padding: 0;
        line-height: 1;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .context-items {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;

      .context-item {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 12px;
        color: white;
        display: flex;
        align-items: center;
        gap: 6px;
        position: relative;

        .context-item-title {
          opacity: 0.8;
        }

        .context-item-remove {
          background: none;
          border: none;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          padding: 0;
          font-size: 14px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: white;
          }
        }

        &.selection {
          background: rgba(0, 122, 255, 0.15);
          flex-direction: column;
          align-items: flex-start;

          .selection-text {
            font-size: 11px;
            opacity: 0.7;
            margin-top: 2px;
            font-style: italic;
          }

          .context-item-remove {
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }

        .context-content, .context-url {
          font-size: 0.8em;
          color: #666;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .chat-input {
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;

    .input-wrapper {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 8px;

      textarea {
        width: 100%;
        background: transparent;
        border: none;
        color: white;
        font-size: 14px;
        padding: 4px;
        resize: none;
        min-height: 24px;
        margin-bottom: 0;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
        }
      }

      .input-actions {
        display: flex;
        gap: 8px;
        align-items: center;
        align-self: flex-end;
        margin-top: 4px;

        .action-button.model-button {
          padding: 4px 8px;
          font-size: 12px;
          background: none;
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: rgba(255, 255, 255, 0.8);
          border-radius: 20px;
          cursor: pointer;
          
          &:hover {
            background: rgba(255, 255, 255, 0.1);
            color: white;
          }
        }

        .chatInputButton {
          padding: 4px 8px;
          border-radius: 20px;
          background: #007AFF;
          color: white;
          border: none;
          cursor: pointer;
          font-size: 12px;
          line-height: 1;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.model-selector {
  position: relative;

  .model-dropdown {
    position: absolute;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    min-width: 200px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

    .model-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: 8px 12px;
      border: none;
      background: none;
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: white;
      }

      &.active {
        color: white;
      }

      .check {
        color: #007AFF;
      }
    }
  }
}

.streaming-overlay {
  position: absolute;
  bottom: 100px;  // Position above the input area
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
  
  .streaming-content {
    color: #fff;
    
    .streaming-indicator {
      font-size: 14px;
      margin-bottom: 8px;
      color: #4CAF50;
    }
    
    .streaming-text {
      font-family: monospace;
      white-space: pre-wrap;
      word-break: break-word;
      max-height: 100px;
      overflow-y: auto;
      padding: 8px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  }
}


// .space-chat {
//   position: fixed;
//   bottom: 20px;
//   right: 20px;
//   width: 350px;
//   height: 500px;
//   background-color: #fff;
//   border-radius: 10px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   z-index: 1000;
//   transform: translateX(400px);
//   transition: transform 0.3s ease;
// }

// .space-chat.visible {
//   transform: translateX(0);
// }

// .chat-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 12px 16px;
//   background-color: #f5f5f5;
//   border-bottom: 1px solid #e0e0e0;
// }

// .chat-title {
//   font-weight: 600;
//   display: flex;
//   align-items: center;
//   gap: 10px;
// }

// .chat-controls {
//   display: flex;
//   gap: 8px;
// }

// .chat-control-button {
//   background: none;
//   border: none;
//   cursor: pointer;
//   padding: 4px;
//   border-radius: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .chat-control-button:hover {
//   background-color: #e0e0e0;
// }

// .chat-messages {
//   flex: 1;
//   overflow-y: auto;
//   padding: 16px;
//   display: flex;
//   flex-direction: column;
//   gap: 12px;
// }

// .chat-message {
//   max-width: 80%;
//   padding: 10px 12px;
//   border-radius: 12px;
//   position: relative;
// }

// .chat-message.user {
//   align-self: flex-end;
//   background-color: #0084ff;
//   color: white;
// }

// .chat-message.ai {
//   align-self: flex-start;
//   background-color: #f0f0f0;
//   color: #333;
// }

// .chat-message.system {
//   align-self: center;
//   background-color: #f5f5f5;
//   color: #666;
//   font-style: italic;
//   font-size: 0.9em;
//   max-width: 90%;
// }

// .message-content {
//   word-break: break-word;
// }

// .message-timestamp {
//   font-size: 0.7em;
//   opacity: 0.7;
//   margin-top: 4px;
//   text-align: right;
// }

// .chat-input-container {
//   padding: 12px 16px;
//   border-top: 1px solid #e0e0e0;
// }

// .input-wrapper {
//   display: flex;
//   background-color: #f5f5f5;
//   border-radius: 20px;
//   padding: 8px 12px;
// }

// .chat-input {
//   flex: 1;
//   border: none;
//   background: transparent;
//   resize: none;
//   min-height: 24px;
//   max-height: 120px;
//   outline: none;
//   font-family: inherit;
//   font-size: 14px;
// }

// .send-button {
//   background: none;
//   border: none;
//   cursor: pointer;
//   color: #0084ff;
//   padding: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .send-button:disabled {
//   color: #ccc;
//   cursor: not-allowed;
// }

// .loading-indicator {
//   display: flex;
//   gap: 4px;
//   justify-content: center;
// }

// .loading-indicator span {
//   animation: loading 1.4s infinite ease-in-out;
//   background-color: #ccc;
//   border-radius: 50%;
//   display: inline-block;
//   height: 8px;
//   width: 8px;
// }

// .loading-indicator span:nth-child(1) {
//   animation-delay: 0s;
// }

// .loading-indicator span:nth-child(2) {
//   animation-delay: 0.2s;
// }

// .loading-indicator span:nth-child(3) {
//   animation-delay: 0.4s;
// }

// @keyframes loading {
//   0%, 80%, 100% {
//     transform: scale(0);
//     opacity: 0.5;
//   }
//   40% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }

// .cursor-blink {
//   display: inline-block;
//   width: 2px;
//   height: 16px;
//   background-color: currentColor;
//   margin-left: 2px;
//   animation: blink 1s infinite;
// }

// @keyframes blink {
//   0%, 100% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0;
//   }
// }

// .model-selector {
//   margin-left: 10px;
// }

// .model-select {
//   padding: 4px 8px;
//   border-radius: 4px;
//   border: 1px solid #ddd;
//   font-size: 12px;
//   background-color: white;
// }

// .tool-execution-indicator {
//   margin-top: 8px;
//   text-align: center;
//   font-size: 12px;
//   color: #666;
//   font-style: italic;
// } 