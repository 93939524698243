.edit-proposal {
  background: rgba(255,255,255,0.05);
  border-radius: 8px;
  
  margin: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  .edit-proposal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 0.9em;
    padding: 8px;
    
    .edit-title {
      display: flex;
      align-items: baseline;
      gap: 6px;
      color: var(--text-secondary);
      
      .entity-name {
        color: white;
        font-weight: 500;
      }
      
      .file-path {
        color: var(--text-secondary);
        font-size: 0.9em;
      }
    }
    
    .edit-proposal-actions {
      display: flex;
      gap: 4px;
      
      button {
        padding: 2px 8px;
        min-width: auto;
        font-size: 12px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        
        &.preview-button {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.8);

          &:hover {
            background: rgba(255, 255, 255, 0.2);
            color: white;
          }
        }
        
        &.accept-button {
          background: #007AFF;
          color: white;

          &:hover {
            background: #0066dd;
          }
          
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        
        &.reject-button {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.8);

          &:hover {
            background: rgba(255, 70, 70, 0.2);
            color: #ff4646;
          }
        }
      }
    }
  }

  .edit-proposal-content {
    padding: 12px;
    .edit-original, .edit-proposed {
      margin: 6px 0;
      
      .label {
        font-size: 0.8em;
        color: var(--text-secondary);
        margin-bottom: 4px;
      }
      
      .text {
        background: var(--surface-2);
        padding: 8px;
        border-radius: 4px;
        font-family: monospace;
        font-size: 0.9em;
        white-space: pre-wrap;
      }
    }
    
    .edit-proposed .text {
      border-left: 2px solid var(--accent);
    }
  }
} 