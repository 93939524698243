.space-container {
    position: relative; /* Ensures parent bounds work */
    width: 100%;
    height: 100vh;
    overflow: hidden;
    // background: #f0f0f0;
}

.spaceTitle {
  position: fixed;
  top: 8px;
  left: 8px;
  z-index: 1000;
  font-size: 13px;
  color: #666;
  display: flex;
  align-items: center;

  .activeWindowTitle {
    margin-left: 8px;
    color: #333;
    font-weight: 500;
  }
}

.window {
  position: relative;
  z-index: var(--window-z-index, 0);
  
  &.focused {
    z-index: var(--window-z-index, 0);
  }
}

.browser-view-container {
  z-index: calc(var(--window-z-index, 0) - 1); // Keep BrowserView below window chrome
}