
.gui.appWrapper {
    // gui specific styles
    border-radius: 15px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    // // slight illumination source above
    box-shadow: inset 0 2px 0px rgba(255, 255, 255, 0.25);
    .appContent {
        border-radius: 15px;
    }
}
.standalone.appWrapper {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    .appContent {
        border-radius: 15px 15px 0 0; // top corners only
    }
    .standaloneAppControls {
        display:flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .logoutButton {
            font-size: 12px;
            font-weight: 500;
            color: #777;
        }
        .username {
            font-size: 12px;
            font-weight: 500;
            color: #777;
        }
    }
}
.appWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    // Visual styling       
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(32px);
    border: none;

}
  
.appHeader {
    padding: 5px 10px 5px 20px;
    color: white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8); /* Subtle text shadow */
    font-weight: 900;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .appControls {
        display:flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
}


.headerButton {
    // background: none;
    background-color: rgba(0, 0, 0, 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: white;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    text-shadow: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-top: 2px; // visual correction for white 2px shadow on window above it
    border-radius: 50px; // round
    &:hover {
        background-color: rgba(0, 0, 0, 25%);
    }
}

.appContent {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow:
    0px 4px 34px 0px rgba(0, 0, 0, 0.65),
    inset 0 2px 0px rgba(255, 255, 255, 0.25);
  border: solid .5px #777;
  flex-grow: 1;
  overflow-y: scroll;
}

// **************************************************
// TABS *********************************************
// **************************************************

.tabContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

// .tabButton {
//     padding: 10px 20px;
//     border: none;
//     background-color: #f5f5f5;
//     cursor: pointer;
//     font-weight: bold;
//     margin-right: 5px;
//     transition: background-color 0.3s ease;
// }
.tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    font-weight: normal;
    height: 100%;
    padding: 0 4px;
    border-radius: 4px;
    &:hover {
        background-color: rgba(0, 0, 0, 25%);
    }
}
.active {
    background-color: rgba(255,255,255, 0.1);
}
.tabButton {
    // background: none;
    background-color: rgba(0, 0, 0, 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: white;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    vertical-align: middle;
    text-shadow: none;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: 2px; // visual correction for white 2px shadow on window above it
    border-radius: 50px; // round
    &:hover {
        background-color: rgba(0, 0, 0, 25%);
    }
}

.tabButton.active {
    background-color: #ddd;
    border-bottom: 2px solid #007BFF;
}