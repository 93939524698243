.browser-app {
  display: flex;
  flex-direction: column;
  height: 100%;

  .browser-header {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    display: flex;
    gap: 8px;

    .browser-controls {
      display: flex;
      gap: 4px;

      button {
        padding: 4px 8px;
        cursor: pointer;
        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .url-form {
      display: flex;
      flex: 1;
      gap: 8px;
      font-size: 14px;

      input {
        flex: 1;
        padding: 6px 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        
        &:focus {
          outline: none;
          border-color: #4a90e2;
          box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
        }
      }
      
      button {
        margin-left: 8px;
        padding: 6px 12px;
        background: #4a90e2;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        
        &:hover {
          background: #357abd;
        }
      }
    }
  }

  .browser-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .browser-viewport {
      flex: 1;
      position: relative;
      
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        display: block;
      }
      
      .selection-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        &::selection {
          background: rgba(0, 122, 255, 0.3);
        }
      }
    }
  }
}

.tab-bar {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  padding: 2px 8px 0;
  font-size: 12px;
  
  .tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: #e4e4e4;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    min-width: 100px;
    max-width: 200px;
    
    &.active {
      background: white;
    }
    
    .tab-favicon {
      width: 16px;
      height: 16px;
    }
    
    .tab-title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .tab-close {
      opacity: 0.5;
      &:hover { opacity: 1; }
    }
  }
  
  .new-tab {
    padding: 4px 8px;
    margin-left: 4px;
  }
}
