@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');
$viewport_padding: 12px;

.desktop {
    // padding: $viewport_padding;
    height: 100vh;
    position: relative; // Makes reference point for the WindowWrapper
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .logoutButton {
      font-size: 12px;
      position: absolute;
      top: 10px;
      right: 10px;
      color: #777;
    }
}
/* Pseudo-element to hold the background image */
.desktop::before {
    pointer-events: none; // ← IMPT: Allow interaction with elements above pseudo ::before
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Ensure the background stays behind content */
    background-image: url('assets/bg_image.jpeg');
    background-size: cover; /* Ensures the image covers the whole page */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    transition: filter 0.3s ease, transform 0.6s ease; /* Smooth transition for blur */
}

/* When an app is open, blur the background image and add a 20% opacity black overlay */
.desktop.blurred::before {
    filter: blur(8px); /* Adjust the blur radius as needed */
    transform: scale(1.05);
    background-color: rgba(32, 32, 32, 0.75); /* opacity black overlay */
    background-blend-mode: overlay; /* Blend the overlay with the image */
    filter: blur(5px);
}
  
.group {
    position: absolute; /* Respect the padding of the .desktop */
    top: $viewport_padding;
    right: $viewport_padding;
    left: $viewport_padding;
    bottom: $viewport_padding;
    box-sizing: border-box;
    z-index: 1000;
}
   
.appButtons {
    margin-bottom: 20px;
    font-size: 14px;

    button {
        min-height: 32px;
        padding: 0 12px;
        border-radius: 8px;
        border: 0.5px solid rgba(255, 255, 255, 0.30);
        background: #70707076;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(121, 121, 121, 0.20);
        color: #ffffffda;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 17.6px */
        transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1); /* Slightly lighter on hover */
          border-color: rgba(255, 255, 255, 0.5); /* Lighten border on hover */
        }
      
        &:active {
          transform: scale(0.98); /* Slight scale on active state */
          background-color: rgba(255, 255, 255, 0.2); /* Darker background on click */
        }
      
        &:focus {
          outline: 2px solid rgba(255, 255, 255, 0.5); /* Custom focus outline */
          outline-offset: 2px;
        }
      
        &:disabled {
          background-color: rgba(121, 121, 121, 0.5); /* Grayed-out background for disabled */
          border-color: rgba(255, 255, 255, 0.15); /* Lighter border for disabled */
          cursor: not-allowed;
          opacity: 0.7;
        }
    }

}

.appWindow {
    position: relative;
    z-index: 6;
    width: 100%;
    height: 100%; 
}