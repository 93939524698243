.systemViewer {
  display: flex;
  height: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr; // Default: Only one column (full-width mainContent)
  grid-template-rows: 26px auto; // Header + Content
  height: 100%;
  width: 100%;

  &.withSidebar {
    grid-template-columns: 1fr 250px; // Add the sidebar column dynamically
  }
}

.header {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 0px 4px 28px;
  color: #999;
  border-bottom: 0.5px #c7c7c7 solid;
  grid-column: 1 / 2;
}

.mainContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  grid-column: 1 / 2;
}

.sidebar {
  border-left: 1px solid #e0e0e0;
  background: #eeeeee;
  font-size: 12px;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  overflow-y: auto;
  
  .sidebarHeader {
    padding: 12px 16px;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0;
  }
  .jsonBlob {
    font-size: 10px;
    color: #555;
    
    pre {
      white-space: pre;
      font-family: monospace;
      padding: 8px;
      background: #e5e5e5;
      border-radius: 4px;
      border: 1px solid #ddd;
      overflow-x: auto;
      max-width: 100%;
    }
  }
}

.row {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s ease-out;
  padding: 3px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0s;
  }

  .rowContent, .rowDetails {
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .rowContent {
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 2px;
    overflow: hidden;
    flex: 1;

    &.noChildren {
      padding-left: 22px;
    }

    .expandCaret {
      width: 20px;
      text-align: center;
      border-radius: 4px;
      color: #777;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .entityName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    }
    .normalGroup {
      font-weight: 600;
      color: #333;
    }
    .systemGroup {
      font-weight: 600;
      color: #1a098a;
    }
  }

  .rowDetails {
    color: #999;
    
    .deleteButton {
      color: rgb(247, 100, 100);
    }
  }

  &:hover .deleteButton {
    opacity: 1;
    cursor: pointer;
  }

  &.selected {
    background-color: rgb(30, 121, 240);
    color: white;
    font-weight: 500;
    .systemGroup {
      color: white;
    }
    
    .expandCaret {
      color: #c8c8c8;
    }
    
    &:hover {
      background-color: rgb(30, 121, 240);
      outline: solid 1.5px #2bdfff;
    }
  }
}

.childRows {
  margin-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  
  .row {
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 50%;
      width: 15px;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.greenDot {
  color: rgb(0, 216, 0);
  margin-left: 5px;
  font-size: 20px;
}

.verifiedBadge {
  fill: #999;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.hovered {
  background-color: rgba(0, 0, 0, 0.1);
}

.entityDetails {
  .detailSection {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }
  }

  .detailHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    .entityIcon {
      font-size: 1.5em;
    }

    .entityName {
      font-size: 1.1em;
      font-weight: 500;
      word-break: break-word;
    }
  }

  .detailRow {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    font-size: 0.9em;

    .label {
      color: #666;
      flex: 0 0 80px;
    }

    .value {
      color: #333;
      text-align: right;
      word-break: break-all;
    }
  }
}


.stack-icon {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  .card {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    transition: transform 0.2s ease-out;

    // Different colors for each card
    &:nth-child(1) { 
      background: #4A90E2;  // blue
      transform: translateY(-2px); 
    }
    &:nth-child(2) { 
      background: #7ED321;  // green
      transform: translateY(0); 
    }
    &:nth-child(3) { 
      background: #F8E71C;  // yellow
      transform: translateY(2px); 
    }
  }

  &:hover {
    .card {
      &:nth-child(1) { transform: translateY(-2px) rotate(3deg); }
      &:nth-child(2) { transform: translateY(0); }
      &:nth-child(3) { transform: translateY(2px) rotate(-3deg); }
    }
  }
} 

.spaceWindows {
    padding-left: 24px;
    
    .windowPreview {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        padding: 4px 8px;
        border-bottom: 1px solid #eee;
        
        .windowType {
            color: #666;
            font-size: 0.9em;
        }
    }
}

.entityBrowser {
  height: 100%;
  display: flex;
  flex-direction: column;

  .gridContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.3s ease;

    &.withSidebar {
      flex-direction: row;

      .mainContent {
        flex: 2;
        border-right: 1px solid #ddd;
      }

      .sidebar {
        flex: 1;
        min-width: 300px;
        max-width: 400px;
      }
    }
  }

  .header {
    padding: 8px 16px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
  }

  .mainContent {
    flex: 1;
    overflow: auto;
  }

  .sidebar {
    display: flex;
    flex-direction: column;

    .sidebarHeader {
      padding: 8px 16px;
      border-bottom: 1px solid #ddd;
    }
  }
}