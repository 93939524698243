.thread-history {
  position: absolute;
  top: 48px; // Below header
  right: 8px;
  width: 300px;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 10001;

  .thread-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      padding: 0 8px;
    }

    .close-button {
      padding: 4px 8px;
      background: none;
      border: none;
      color: var(--text-secondary);
      cursor: pointer;

      &:hover {
        color: white;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }
  }

  .thread-list {
    max-height: 400px;
    overflow-y: auto;
    padding: 4px;
    gap: 2px;

    .thread-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px;
      border: none;
      border-radius: 4px;
      background: none;
      text-align: left;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      .thread-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .thread-header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          gap: 8px;
          width: 100%;

          .thread-title {
            color: white;
            font-size: 13px;
            flex: 1;
            text-align: left;
          }
        }

        .thread-meta-line {
          display: flex;
          align-items: center;
          gap: 4px;
          color: var(--text-secondary);
          font-size: 11px;

          .thread-time,
          .thread-meta {
            display: inline;
          }

          .thread-time {
            white-space: nowrap;
          }
        }
      }

      .thread-type {
        padding: 2px 6px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        font-size: 11px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
} 