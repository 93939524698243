.model-selector {
  position: relative;
  z-index: 10000;

  .model-dropdown {
    position: absolute;
    z-index: 10001;
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    min-width: 200px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    bottom: 100%;
    right: 0;
    margin-bottom: 8px;

    .model-section-header {
      padding: 8px 12px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .model-option {
      position: relative;
      z-index: 10002;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: 8px 12px;
      border: none;
      background: none;
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
      cursor: pointer;
      border-radius: 4px;
      pointer-events: auto;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: white;
      }

      &.active {
        color: white;
      }

      .check {
        color: #007AFF;
      }

      &.custom {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          flex-shrink: 0;
          opacity: 0.7;
        }

        span {
          flex: 1;
        }
      }
    }
  }
}

.action-button.model-button {
  padding: 4px 8px;
  font-size: 12px;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
} 