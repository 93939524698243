.electron-window {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.focused {
    border-color: #2196f3;
    box-shadow: 0 4px 12px rgba(33, 150, 243, 0.2);
  }

  .window-header {
    height: 32px;
    background: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: move;

    .window-title {
      flex: 1;
      font-size: 13px;
      color: #333;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      width: 24px;
      height: 24px;
      border: none;
      background: none;
      color: #666;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        color: #333;
      }
    }
  }

  .window-content {
    flex: 1;
    position: relative;
  }
} 