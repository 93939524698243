.web-document-app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .url-input-form {
    padding: 20px;
    display: flex;
    gap: 10px;

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      padding: 8px 16px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .web-document-content {
    flex: 1;
    overflow: hidden;

    iframe {
      border: none;
    }
  }
}

.error-message {
  color: #ff4444;
  margin-top: 8px;
  font-size: 14px;
} 